.App {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

#main {
  display: flex;
  flex-flow: column wrap;
}

#annotator {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#alert {
  text-align: center;
}

#split-panel {
  display: flex;
  flex-flow: row wrap;
}

#scraped-info {
  display: flex;
  flex-flow: column wrap;
  align-items: space-between;
  justify-content: flex-start;
  flex: 1;
  padding: 10px 10px;

  min-height: 100vh;
  background: #dddddd;
}

#annotation-form {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 10px;
  flex: 1;
}

.fullImage {
  max-width: 600px;
}

.instructions {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.instructionsSection {
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10px;
  flex: 1;
  gap: 10px;
}

.clickable {
  cursor: pointer;
}

.modalCloseButton {
  position: 'absolute';
  right: 0;
  top: 0;
  color: 'red';
}

.modalImageContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modalImage {
  width: 90%;
}

#modalCloseContainer {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}

.tooltip {
  text-decoration: "underline";
  cursor: "help";
}

#strippedDOM {
  max-width: 50% !important;
}

#linkDOM {
  max-width: 50% !important;
}